'use client';

import React from 'react';
import Link from 'next/link';
import { useRouter, useSearchParams } from 'next/navigation';
import { Button } from 'src/components/Button/Button';
import { SignInButton } from 'src/components/Button/SignInButton';
import { Divider } from 'src/components/Divider/Divider';
import { Input } from 'src/components/Input/Input';
import { REFERER_SEARCH_KEY } from 'src/consts';
import { useBoolean } from 'src/hooks/useBoolean';
import { Translated, useTranslations } from 'src/i18n/TranslationsProvider';
import { useAnalytics } from 'src/modules/analytics/useAnalytics';
import { getFormValues } from 'src/utils/getFormValues';
import { getSupabaseBrowserClient } from 'src/utils/supabase/getSupabaseBrowserClient';

const FORM_KEYS = ['email', 'password'] as const;
type FormKeys = (typeof FORM_KEYS)[number];

export function LoginForm() {
  const supabase = getSupabaseBrowserClient();
  const searchParams = useSearchParams();
  const router = useRouter();
  const [errorText, setErrorText] = React.useState<string | null>(null);
  const [isSubmitting, { toggle: setSubmitting }] = useBoolean();
  const { trackEvent } = useAnalytics();

  const mailPlaceholder = useTranslations('login-page.mail-placeholder');
  const mailLabel = useTranslations('login-page.mail-input-label');
  const passwordPlaceholder = useTranslations(
    'login-page.password-placeholder'
  );
  const passwordLabel = useTranslations('login-page.password-input-label');

  const referer = searchParams?.get(REFERER_SEARCH_KEY) ?? '/my-maps';

  const signInWithGoogle = async () => {
    trackEvent('user_signed_in', {
      provider: 'google',
    });
    supabase.auth.signInWithOAuth({
      options: {
        redirectTo: '/auth/callback',
      },
      provider: 'google',
    });
  };

  const handleFormSubmit = async (event: React.FormEvent<Form<FormKeys>>) => {
    setSubmitting(true);
    event.preventDefault();

    const { email, password } = getFormValues<FormKeys>(
      FORM_KEYS,
      event.currentTarget
    );
    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    if (error) {
      setErrorText('Niepoprawny mail lub hasło');
      setSubmitting(false);
      return;
    }
    trackEvent('user_signed_in', {
      provider: 'email',
    });
    router.refresh();
    router.push(referer);
  };

  return (
    <>
      <form
        className="mx-auto flex max-w-xs flex-col gap-4"
        onSubmit={handleFormSubmit}
      >
        <Input
          name="email"
          type="email"
          label={mailLabel}
          placeholder={mailPlaceholder}
          required
          autoComplete="email"
          id="email"
          data-testid="email-input"
        />
        <Input
          name="password"
          type="password"
          label={passwordLabel}
          placeholder={passwordPlaceholder}
          required
          autoComplete="current-password"
          id="current-password"
          data-testid="password-input"
        />
        {errorText && (
          <p className="text-center text-lg text-secondary">{errorText}</p>
        )}
        <p className="cursor-pointer self-start hover:underline">
          <Link href={'/forgot-password'}>
            <Translated id="login-page.forgot-password" />
          </Link>
        </p>
        <Button align="center" type="submit" loading={isSubmitting}>
          <Translated id="login-page.sign-in-button" />
        </Button>
      </form>
      <div className="flex items-center justify-around gap-2">
        <span>
          <Translated id="login-page.sign-up-label" />
        </span>
        <Link
          href={'/sign-up'}
          className="cursor-pointer text-lg text-secondary hover:underline"
        >
          <Translated id="login-page.sign-up-button" />
        </Link>
      </div>
      <Divider />
      <SignInButton.Google
        onClick={signInWithGoogle}
        className="mx-auto max-w-xs bg-backgroundLight text-lg"
      />
    </>
  );
}
